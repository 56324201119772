exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-james-en-js": () => import("./../../../src/pages/james-en.js" /* webpackChunkName: "component---src-pages-james-en-js" */),
  "component---src-pages-james-js": () => import("./../../../src/pages/james.js" /* webpackChunkName: "component---src-pages-james-js" */),
  "component---src-pages-taina-en-js": () => import("./../../../src/pages/taina-en.js" /* webpackChunkName: "component---src-pages-taina-en-js" */),
  "component---src-pages-taina-js": () => import("./../../../src/pages/taina.js" /* webpackChunkName: "component---src-pages-taina-js" */)
}

